import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App);

// 自封装css
import './css/common.scss';

//设备类型判定
import { createDeviceDetector } from "next-vue-device-detector";
export const device = createDeviceDetector();
app.use(device);

app.use(router).mount('#app')
