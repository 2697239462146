import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [{
    path: '/',
    component: () => import('../views/index.vue')
},{
    path: '/m',
    component: () => import('../views/indexM.vue')
}]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
